import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import Checkbox from "../../components/Checkbox"
import {DateDto, PlayerState, PlayerStateDto, SignupAnswer} from "../../api/dtos"
import {getPlayerState, signup} from "../../api/api"
import {Link} from "react-router-dom"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"
import DateUtil from "../../util/DateUtil";

interface State {
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    loading: boolean
    playerState?: PlayerState
    groupPlayName: string
    playedInLastRound: boolean
    activeSignupMail: boolean
    nextRoundStarts?: DateDto
    signupAnswerLatest?: DateDto
    answer?: SignupAnswer
}

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string, answer: string }>

export default class SignupPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            fatalError: false,
            loading: true,
            groupPlayName: "",
            playedInLastRound: false,
            activeSignupMail: false,
        }
    }

    async componentDidMount() {
        console.log("componentDidMount")
        if (this.props.session) {
            try {
                const {groupplayId, answer} = this.props.match.params
                console.log("answer in url=" + answer);
                if (answer !== undefined) {
                    //answer in URL, save it
                    await this.handleAnswerChange(answer as SignupAnswer)
                }
                const playerState: PlayerStateDto = await getPlayerState(groupplayId, this.props.session.sessionId)

                let stateAnswer: SignupAnswer | undefined = undefined;
                switch (playerState.state) {
                    case PlayerState.DONT_WANNA_JOIN:
                    case PlayerState.WANNA_QUIT:
                        stateAnswer = SignupAnswer.NO;
                        break;
                    case PlayerState.WANNA_JOIN:
                    case PlayerState.PLAYING:
                        stateAnswer = SignupAnswer.YES;
                        break;
                    case PlayerState.WANNA_PAUSE:
                    case PlayerState.PAUSE:
                        stateAnswer = SignupAnswer.PAUSE;
                        break;
                }

                this.setState({
                    playerState: playerState.state,
                    answer: stateAnswer,
                    playedInLastRound: playerState.playedInLastRound,
                    activeSignupMail: playerState.activeSignupMail,
                    signupAnswerLatest: playerState.signupAnswerLatest,
                    nextRoundStarts: playerState.nextRoundStarts,
                    groupPlayName: playerState.groupplayName,
                    loading: false
                })
            } catch (error: any) {
                console.log('ERROR:' + error.type)
                switch (error.type) {
                    default:
                        this.setState({fatalError: true, loading: false})
                        break
                }
            }
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        const {groupplayId} = this.props.match.params
        const fontSize = mobile ? "3rem" : "1rem"
        const width = mobile ? "90vw" : "40rem"
        const height = mobile ? "63rem" : "22rem"
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>)
        }
        if (!this.props.session) { //not logged in
            return (
                <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}>
                    <div style={{
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: height,
                        position: "relative",
                        fontSize: fontSize
                    }}>
                        <p style={{textAlign: "center", width: width, margin: "auto"}}>
                            Om du vill anmäla dig att vara med i gruppspelet måste du
                            först logga in på gruppspelet.se.
                        </p>
                        <p style={{textAlign: "center", width: width, margin: "auto"}}>
                            Har du inget konto? <Link to="/newAccount">Klicka här!</Link>
                        </p>
                        <svg version="1.1" id="arrow" style={{
                            position: "absolute", right: mobile ? "6rem" : "-4rem", top: "1rem",
                            width: "5rem", height: "5rem", fill: "black"
                        }} x="0px" y="0px" viewBox="0 0 288.824 288.824">
                            <g>
                                <path id="Arrow_Up_Right" d="M3.501,285.323c4.668,4.668,12.235,4.668,16.891,0L264.763,40.951v91.382
		c0,6.641,5.39,12.03,12.03,12.03c6.641,0,12.03-5.39,12.03-12.03V12.03c0-3.188-1.239-6.268-3.501-8.529S279.981,0,276.793,0
		H156.49c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h91.382L3.501,268.432C-1.167,273.1-1.167,280.667,3.501,285.323z"
                                />
                            </g>
                        </svg>
                    </div>
                </BasePage>
            )
        } else { //logged in
            const { answer } = this.state;
            return (
                <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}>
                    <div style={{
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: height,
                        fontSize: fontSize
                    }}>
                        {this.state.nextRoundStarts &&
                            <p style={{textAlign: "center", width: width, margin: "1rem auto"}}>
                                Anmälan till nästa omgång i {this.state.groupPlayName} som
                                börjar {DateUtil.dayName(this.state.nextRoundStarts.day)} {this.state.nextRoundStarts.date}
                            </p>}
                        {!this.state.nextRoundStarts &&
                            <p style={{textAlign: "center", width: width, margin: "1rem auto"}}>
                                Anmälan till nästa omgång i {this.state.groupPlayName}
                            </p>}
                        {this.state.signupAnswerLatest &&
                            <p style={{textAlign: "center", width: width, margin: "1rem auto"}}>
                                Svara
                                senast {DateUtil.dayName(this.state.signupAnswerLatest.day)} {this.state.signupAnswerLatest.date}!
                            </p>}
                        <p style={{textAlign: "center", width: width, margin: mobile?"4rem auto 4rem auto":"2rem auto"}}>
                            Jag, {this.props.session.firstName} {this.props.session.lastName}, vill:
                        </p>
                        <div style={{margin: "0 auto 2rem auto"}}>
                            <div style={{display: "flex", alignItems: "center", marginBottom: mobile?"4rem":"1rem"}}>
                                <Checkbox checked={answer === SignupAnswer.YES}
                                          onChange={() => this.handleAnswerChange(SignupAnswer.YES)} id={"yes"}/>
                                <span
                                    style={{width: mobile ? "38rem" : "16rem", marginLeft: "1rem"}}>
                                    vara med i nästa omgång.
                                </span>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginBottom: mobile?"4rem":"1rem"}}>
                                <Checkbox checked={answer === SignupAnswer.PAUSE}
                                          onChange={() => this.handleAnswerChange(SignupAnswer.PAUSE)} id={"pause"}/>
                                <span
                                    style={{width: mobile ? "34rem" : "22rem", marginLeft: "1rem"}}>
                                    Pausa och kanske vara med i omgången efter
                                </span>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Checkbox checked={answer === SignupAnswer.NO}
                                          onChange={() => this.handleAnswerChange(SignupAnswer.NO)} id={"no"}/>
                                <span style={{width: mobile ? "36rem" : "16rem", marginLeft: "1rem"}}>inte vara med alls längre</span>
                            </div>
                        </div>
                        <LinkButton style={{fontSize: fontSize, textAlign: "center", marginTop:"1rem"}}
                                    onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId})}/>
                    </div>
                </BasePage>
            )
        }
    }

    private handleAnswerChange = async (answer : SignupAnswer) => {
        const {groupplayId} = this.props.match.params
        const session = this.props.session
        if (session && answer) {
            this.setState({loading: true, answer: answer})
            try {
                await signup(groupplayId, answer, session.sessionId)
                this.setState({loading: false })
            } catch (error: any) {
                console.log('ERROR' + error)
                this.setState({fatalError: true})
            }
        } else {
            this.setState({fatalError: true})
        }
    }
}
