import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import {InviteDto, PlayerDto, PlayersDto, UpdatePlayerDto, UpdatePlayerGroupDto} from "../../../api/dtos"
import BasePage, {BasePageProps} from "../../BasePage"
import {adminAcceptInvite, deleteInvite, getPlayers, removePlayer, updatePlayer, updatePlayerGroups} from "../../../api/api"
import InvitesTable from "./InvitesTable"
import PlayersTables from "./PlayersTables"
import {mobile} from "../../../App"


type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    fatalError: boolean
    loading: boolean
    players: PlayerDto[]
    originalPlayers: PlayerDto[]
    invites: InviteDto[]
    saveButtonDisabled: boolean
    redirectTo?: string
}

class AdminPlayersPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            players: [],
            originalPlayers: [],
            invites: [],
            saveButtonDisabled: true
        };

        this.handleDeleteInviteClick = this.handleDeleteInviteClick.bind(this);
        this.handleAddInviteClick = this.handleAddInviteClick.bind(this);
        this.handleSaveGroupsClicked = this.handleSaveGroupsClicked.bind(this);
        this.handlePlayerGroupsChanged = this.handlePlayerGroupsChanged.bind(this);
        this.handleSavePlayer = this.handleSavePlayer.bind(this);
        this.removePlayer = this.removePlayer.bind(this);
        this.addPlayerClick = this.addPlayerClick.bind(this);
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params;
        if (this.props.session) {
            try {
                const playersDto: PlayersDto = await getPlayers(groupplayId, this.props.session.sessionId);
                this.setState({
                    players: Object.assign([], playersDto.players),
                    originalPlayers: playersDto.players,
                    invites: playersDto.invites,
                    loading: false
                })
            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        const saveWidth = mobile ? "12rem" : "6rem";
        const addWidth = mobile ? "36rem" : "9rem";
        const signupWidth = mobile ? "43rem" : "11rem";
        return (
            <BasePage adminPage={true} loading={this.state.loading} {...this.props} fatalError={this.state.fatalError}>
                <div>
                    <button style={{width: addWidth}} onClick={this.addPlayerClick}>Lägg till spelare</button>
                    <button style={{width: signupWidth}} onClick={this.mailSignupClick}>Maila ut återanmälan</button>
                </div>
                <InvitesTable invites={this.state.invites} deleteInvite={this.handleDeleteInviteClick}
                              addInvite={this.handleAddInviteClick}/>
                <PlayersTables players={this.state.players}
                               onPlayerGroupsChanged={this.handlePlayerGroupsChanged}
                               removePlayer={this.removePlayer}
                               savePlayer={this.handleSavePlayer}/>
                <div style={{
                    padding: "0.5rem", backgroundColor: "var(--pageBg)",
                    position: "fixed", bottom: 0, right: 0
                }}>
                    <button onClick={this.handleSaveGroupsClicked} disabled={this.state.saveButtonDisabled}
                            style={{width: saveWidth}}>Spara
                    </button>
                </div>

            </BasePage>
        );
    }

    handlePlayerGroupsChanged(players: PlayerDto[]) {
        let anyPlayerChanged: boolean = this.hasAnyPlayerChanged(players, this.state.originalPlayers);
        this.setState({players: players, saveButtonDisabled: !anyPlayerChanged});
    }

    private hasAnyPlayerChanged(players: PlayerDto[], orgPlayers: PlayerDto[]) {
        for (let player of players) {
            for (let orgPlayer of orgPlayers) {
                if (player.account.id === orgPlayer.account.id) {
                    //console.log(player.account.firstName + ' groupNr=' + player.groupNumber + ',' + orgPlayer.groupNumber);
                    if (player.groupNumber !== orgPlayer.groupNumber) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    async handleAddInviteClick(linkId: string) {
        if (this.props.session) {
            this.setState({loading: true});
            const {groupplayId} = this.props.match.params;
            try {
                await adminAcceptInvite(groupplayId, linkId, this.props.session.sessionId);
                this.componentDidMount(); //refresh/reload players
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    async handleDeleteInviteClick(linkId: string) {
        if (this.props.session) {
            const {groupplayId} = this.props.match.params;
            try {
                await deleteInvite(groupplayId, linkId, this.props.session.sessionId);
                const invitesList = this.state.invites;
                let indexToDelete = 0;
                for (let index = 0; index < invitesList.length; index++) {
                    if (invitesList[index].linkId === linkId) {
                        indexToDelete = index;
                        break;
                    }
                }
                invitesList.splice(indexToDelete, 1);
                this.setState({invites: invitesList})
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    async removePlayer(player: PlayerDto) {

        if (this.props.session) {
            this.setState({loading: true});
            const {groupplayId} = this.props.match.params;
            const sessionId = this.props.session.sessionId;
            try {
                await removePlayer(groupplayId, player.account.id, sessionId);
                const {players, originalPlayers} = this.state;
                const updatedPlayers = players.filter(pl => pl.account.id !== player.account.id);
                const updatedOriginalPlayers = originalPlayers.filter(pl => pl.account.id !== player.account.id);
                let anyPlayerChanged: boolean = this.hasAnyPlayerChanged(updatedPlayers, updatedOriginalPlayers);
                this.setState({
                    players: updatedPlayers,
                    originalPlayers: updatedOriginalPlayers,
                    saveButtonDisabled: !anyPlayerChanged,
                    loading: false
                });

            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }

    }

    async handleSavePlayer(player: PlayerDto) {
        if (this.props.session) {
            this.setState({loading: true});
            const {groupplayId} = this.props.match.params;
            const request: UpdatePlayerDto = {
                accountId: player.account.id,
                comment: player.comment,
                state: player.state
            };
            const sessionId = this.props.session.sessionId;
            try {
                await updatePlayer(request, groupplayId, sessionId);
                const players = this.state.players;

                const updatedPlayers = players.map(pl => {
                    if (pl.account.id === player.account.id) {
                        return player;
                    }
                    return pl;
                });
                this.setState({players: updatedPlayers, loading: false});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }

    }

    async handleSaveGroupsClicked() {
        if (this.props.session) {
            this.setState({loading: true});
            const {groupplayId} = this.props.match.params;
            const saveRequest: UpdatePlayerGroupDto[] = [];
            for (const player of this.state.players) {
                saveRequest.push({
                    accountId: player.account.id,
                    groupNumber: player.groupNumber
                })
            }
            try {
                await updatePlayerGroups(saveRequest, groupplayId, this.props.session.sessionId);
                this.setState({
                    saveButtonDisabled: true,
                    originalPlayers: Object.assign([], this.state.players),
                    loading: false
                });
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    private addPlayerClick() {
        const {groupplayId} = this.props.match.params;
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/addPlayer"})
    }

    private mailSignupClick = () => {
        const {groupplayId} = this.props.match.params;
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/admin/sendSignups"})
    }

}

export default AdminPlayersPage;
