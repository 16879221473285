import * as React from "react"
import {mobile} from "../App"

interface Props {
    heading?: string
    preWrapWhiteSpace?: boolean
    center?: boolean
}

export default class Panel extends React.Component<Props> {
    render() {
        const {heading} = this.props;
        const fontSize = mobile ? "2.5rem" : "1rem";
        const headingFontSize = mobile ? "2.75rem" : "1.1rem";
        return (<div style={{
                    position: "relative",
                    width: "46rem",
                backgroundColor: "var(--panelBg)",
                color: "var(--panelText)",
                    margin: mobile ? "0.5rem auto 0.5rem auto" : "0.5rem auto 1rem auto",
                    borderRadius: "0.3rem",
                    padding: "0.5rem",
                    display:"inline-block",
                    whiteSpace: this.props.preWrapWhiteSpace ? "pre-wrap" : "normal",
                    fontSize: fontSize,
                    textAlign: this.props.center ? "center" : "left"
                }}>
                    {heading && <div style={{fontWeight:"bold", fontSize:headingFontSize, marginBottom:mobile?"1.5rem":"0.5rem"}}>{heading}</div>}
                    {this.props.children}
                </div>
        );
    }
}