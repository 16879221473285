import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router";
import {createAccountWithInvite, getInvite} from "../../api/api";
import {ErrorType, InviteDto, NewAccountWithInviteDto, SessionDto} from "../../api/dtos"
import FullPageSpinner from "../../components/FullPageSpinner";
import EmailInput from "../../components/EmailInput";
import TextInput from "../../components/TextInput";
import PhoneInput from "../../components/PhoneInput";
import BasePage, {BasePageProps} from "../BasePage";
import FullPageMessage from "../../components/FullPageMessage";
import {ErrorMessage} from "../../components/ErrorMessage";
import {mobile} from "../../App";

interface InvitePageProps {
    onSessionChange: (rememberMe: boolean, session: SessionDto) => void
}

interface State {
    fullPageErrorMessage?: string
    errorMessage?: string
    invite?: InviteDto
    loading: boolean
    email: string
    firstName: string
    lastName: string
    phone: string
    password1: string
    password2: string
    fatalError: boolean
    emailValid: boolean
    phoneValid: boolean
    passwordsInvalid: boolean
    accountCreated: boolean
}

type Props =
    BasePageProps
    & InvitePageProps
    & RouteComponentProps<{ groupplayId: string, linkId: string }>

export default class InvitePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            password1: '',
            password2: '',
            emailValid: true,
            phoneValid: true,
            passwordsInvalid: false,
            accountCreated: false
        };
        this.okClick = this.okClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
        this.handleLastnameChange = this.handleLastnameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handlePassword1Change = this.handlePassword1Change.bind(this);
        this.handlePassword2Change = this.handlePassword2Change.bind(this);
        this.okDisabled = this.okDisabled.bind(this);
    }

    async componentDidMount() {
        const {linkId} = this.props.match.params;
        const session = this.props.session;
        try {
            let inviteDto: InviteDto = await getInvite(linkId, session ? session.sessionId : undefined);
            this.setState({
                invite: inviteDto,
                email: inviteDto.email,
                firstName: inviteDto.firstName,
                lastName: inviteDto.lastName
            });

            setTimeout(() => {
                if (!session && inviteDto.session) {
                    console.log('no session before, new session with invite!');
                    this.props.onSessionChange(false, inviteDto.session);
                }
                if (session && inviteDto.session && session.sessionId !== inviteDto.session.sessionId) {
                    console.log('session before, but not same as invite.. using invite session!');
                    this.props.onSessionChange(true, undefined);
                    this.props.onSessionChange(false, inviteDto.session);
                }
                if (session && !inviteDto.session) {
                    console.log('session before, but not on invite.. logging out!');
                    this.props.onSessionChange(true, undefined);
                }
            }, 100);

            if (!session && !inviteDto.hasAccount) {
                this.setState({loading: false})
            }
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case ErrorType.INVALID_LINK_ID:
                    this.setState({fullPageErrorMessage: "Felaktig länk..", loading: false});
                    break;
                default:
                    this.setState({fullPageErrorMessage: error.type, loading: false});
                    break;
            }

        }
    }

    handleEmailChange(currentValue: string, valid: boolean) {
        this.setState({email: currentValue, emailValid: valid});
    }

    handleFirstnameChange(currentValue: string) {
        this.setState({firstName: currentValue});
    }

    handleLastnameChange(currentValue: string) {
        this.setState({lastName: currentValue});
    }

    handlePhoneChange(currentValue: string, valid: boolean) {
        this.setState({phone: currentValue, phoneValid: valid});
    }

    handlePassword1Change(currentValue: string) {
        this.setState({
            password1: currentValue,
            passwordsInvalid: this.state.password2 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }

    handlePassword2Change(currentValue: string) {
        this.setState({
            password2: currentValue,
            passwordsInvalid: this.state.password1 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }

    render() {
        const invite = this.state.invite;
        if (this.state.fullPageErrorMessage) {
            return (<FullPageMessage text={this.state.fullPageErrorMessage}/>);
        }
        if (invite && invite.session && this.props.session && this.props.session.sessionId === invite.session.sessionId) {
            return (<Redirect to={"/gruppspel/" + invite.groupplayId + "/signup"}/>);
        }
        if (invite && this.props.session && this.state.accountCreated) {
            return (<Redirect to={"/gruppspel/" + invite.groupplayId + "/signup"}/>);
        }
        if (!invite || invite.session || this.props.session) {
            return (<FullPageSpinner/>);
        }
        if (this.state.invite) {
            const fontSize = mobile ? "3rem" : "1rem";
            const textMargin = mobile ? "1.5rem" : "5rem";

            return (
                <BasePage {...this.props} noLoginButton={true} fatalError={this.state.fatalError}>
                    <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                        <div style={{fontSize: fontSize, margin: "0 " + textMargin}}>
                            <p style={{textAlign: "center"}}>Hej {this.state.invite.firstName} {this.state.invite.lastName}</p>
                            <p>Du har blivit inbjuden att börja i {this.state.invite.groupplayName}. Först måste
                                måste du registrera ett konto hos gruppspelet.se:
                            </p>
                        </div>
                        <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                            <EmailInput disabled={true} value={this.state.email}
                                        onChange={this.handleEmailChange}/>
                            <TextInput value={this.state.firstName} onChange={this.handleFirstnameChange}
                                       placeHolder={"Förnamn"}/>
                            <TextInput value={this.state.lastName} onChange={this.handleLastnameChange}
                                       placeHolder={"Efternamn"}/>
                            <PhoneInput onChange={this.handlePhoneChange} allowEmpty={true} autoFocus={true}
                                        placeHolder={"Telefon"}/>
                            <TextInput onChange={this.handlePassword1Change} placeHolder={"Lösenord"}
                                       invalid={this.state.passwordsInvalid} password={true}/>
                            <TextInput onChange={this.handlePassword2Change} placeHolder={"Lösenord igen"}
                                       invalid={this.state.passwordsInvalid} password={true}/>
                            <ErrorMessage text={this.state.errorMessage}/>
                            <button onClick={this.okClick} disabled={this.okDisabled()}> Okej</button>
                        </div>
                    </div>
                </BasePage>
            );
        }
    }

    okDisabled(): boolean {
        return !this.state.emailValid ||
            !this.state.phoneValid ||
            this.state.firstName.length === 0 ||
            this.state.lastName.length === 0 ||
            this.state.phone.length === 0 ||
            this.state.password1.length < 4 ||
            this.state.passwordsInvalid;
    }

    async okClick() {
        if (this.state.invite) {
            this.setState({loading: true});
            const {linkId} = this.props.match.params;
            try {
                const request: NewAccountWithInviteDto = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    phone: this.state.phone,
                    password: this.state.password1,
                    inviteLinkId: linkId
                };
                const session: SessionDto = await createAccountWithInvite(request);
                this.setState({accountCreated: true});
                this.props.onSessionChange(false, session);
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    case ErrorType.INVALID_LINK_ID:
                        this.setState({errorMessage: "Felaktig länk.."});
                        break;
                    case ErrorType.SEND_MAIL_FAILURE:
                        this.setState({errorMessage: "Misslyckades att maila: " + error.message});
                        break;
                    default:
                        this.setState({fatalError: true, errorMessage: error.type});
                        break;
                }
            }
        }
    }
}
