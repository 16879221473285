import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getBilling} from "../../api/api";
import {BillingDto} from "../../api/dtos";
import Table from "../../components/Table";
import TableRow from "../../components/TableRow";
import TableText from "../../components/TableText";
import {mobile} from "../../App";
import {Link} from "react-router-dom";
import ClipboardUtil from "../../util/ClipboardUtil";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string, roundNr: string }>

interface State {
    loading: boolean
    fatalError: boolean
    billings: BillingDto[]
}

export default class HistoryBillingPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
            billings: [],
        }
    }

    async componentDidMount() {
        if (this.props.session) {
            this.setState({loading: true});
            const {groupplayId, roundNr} = this.props.match.params;
            const roundNrInt = parseInt(roundNr);
            if (isNaN(roundNrInt)) {
                this.setState({fatalError: true, loading: false});
                return;
            }
            try {
                const billingDtos = await getBilling(groupplayId, roundNrInt, this.props.session.sessionId);
                this.setState({billings: billingDtos, loading: false});
            } catch (error: any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    case "NOT_FOUND":
                        //just ignore..
                        this.setState({loading: false});
                        break;
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        }
    }

    render() {
        const {billings} = this.state;
        const {groupplayId, roundNr} = this.props.match.params;
        return (
            <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                      hideStandingsButton={true}>
                <div style={{textAlign: "center", fontSize: mobile ? "2.5rem" : "1.5rem"}}>Betalningsinformation för
                    omgång {roundNr}</div>

                <Link style={{textAlign: "center", fontSize: mobile ? "2rem" : "1rem"}}
                      to={"/gruppspel/" + groupplayId + "/history"}>tillbaka</Link>
                <button style={{width: mobile ? "48rem" : "10rem"}}
                        onClick={() => this.copyToClipboard(billings)}>Kopiera
                </button>
                <Table>
                    {billings
                        .sort((a, b) => {
                            if (a.player.firstName > b.player.firstName) return 1;
                            if (a.player.firstName < b.player.firstName) return -1;
                            if (a.player.lastName > b.player.lastName) return 1;
                            if (a.player.lastName < b.player.lastName) return -1;
                            return 0;
                        })
                        .map((b, i) => {
                            return (<TableRow key={b.player.id} lastRow={billings.length === (i + 1)}>
                                <TableText left="0.5rem" mobLeft="0.5rem" mobFirstRow>{i + 1}.</TableText>
                                <TableText left="2.5rem" mobLeft="0.5rem"
                                           mobFirstRow>{b.player.firstName} {b.player.lastName}</TableText>
                                <TableText left="15rem" mobLeft="0.5rem" mobFirstRow>{b.description}</TableText>
                                <TableText right="0" mobLeft="0.5rem" mobFirstRow>{b.totalPrice}</TableText>
                            </TableRow>)
                        })}
                </Table>
            </BasePage>
        );
    }

    private copyToClipboard = (billings: BillingDto[]) => {
        const maxNameLength = 1 + Math.max(...(billings
            .map(b => b.player.firstName + b.player.lastName)
            .map(s => s.length)))
        const maxDescLength = Math.max(...(billings
            .map(b => b.description)
            .map(s => s.length)))
        let rows: string[] = billings
            .filter(b => b.totalPrice !== "0kr")
            .flatMap((b, index) => {
            const name = (b.player.firstName + " " + b.player.lastName)
                .padEnd(maxNameLength, " ");
            const desc = b.description.padEnd(maxDescLength, " ");
            const number = ((index+1) + ".").padStart(4, " ");
            return number + " " + name + desc + b.totalPrice;
        } );
        ClipboardUtil.copyToClipboard(rows.join("\n"));
    };
}
