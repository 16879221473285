import * as React from "react"
import DateUtil from "../../util/DateUtil"
import DateInput from "../../components/DateInput"
import TimeInput from "../../components/TimeInput"
import Spinner from "../../components/Spinner"
import {SessionDto, UpdateRoundOpenUntilDto} from "../../api/dtos"
import {updateRoundOpenUntil} from "../../api/api"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"

interface Props {
    date: string
    hour: number
    minute: number
    onChange: () => void
    groupplayId: string
    roundNr: number
    session?: SessionDto
}

interface State {
    edit: boolean
    dateString: string
    dateValid: boolean
    timeString: string
    originalTimeString: string
    timeValid: boolean
    loading: boolean
}

export default class RoundOpenUntilWidget extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const {date, hour, minute} = this.props;
        const timeString: string = (hour < 10 ? ("0" + hour) : hour) + ""
            + (minute < 10 ? ("0" + minute) : minute);
        this.state = {
            edit: false,
            dateString: date,
            timeString: timeString,
            originalTimeString: timeString,
            dateValid: true,
            timeValid: true,
            loading: false
        };
        this.editMode = this.editMode.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
    }

    render() {
        const {date,session, groupplayId} = this.props;
        const {edit, dateString, dateValid, timeString, loading} = this.state;
        const fontSize = mobile ? "3rem" : "1rem";
        const admin: boolean = session ? session.adminGroupplayIds.includes(groupplayId) : false;
        if (loading) {
            return <Spinner/>;
        }
        if (edit && !mobile) {
            return (<div style={{
                margin: "1rem auto 5rem auto",
                display: "flex",
                fontSize: fontSize,
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <div style={{textAlign: "center", margin: "0.3rem"}}>Omgångens slut o sista tid att bokföra resultat:
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    {dateValid ?
                        <div style={{
                            display: "inline-block",
                            width: "4rem",
                            margin: "0.3rem"
                        }}>{DateUtil.dateToDayName(dateString)}</div>
                        : <div style={{display: "inline-block", width: "4rem", margin: "0.3rem"}}/>}
                    <DateInput onChange={this.onDateChange} value={dateString}/>
                    <span style={{marginLeft: "1rem"}}>klockan</span>
                    <TimeInput onChange={this.onTimeChange} value={timeString} />
                </div>
                <div style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                    <LinkButton onClick={() => this.setState({edit: false})}/>
                    <button style={{width: "10rem", marginLeft: "2rem", alignSelf: "flex-end"}}
                            onClick={this.saveClicked} disabled={this.saveButtonDisabled()}>Spara
                    </button>
                </div>
            </div>);
        }
        if (edit && mobile) {
            return (<div style={{
                margin: "1rem auto 5rem auto",
                display: "flex",
                fontSize: fontSize,
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <div style={{textAlign: "center", margin: "0.3rem"}}>Omgångens slut o sista tid att bokföra resultat:
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    {dateValid ?
                        <div style={{
                            display: "inline-block",
                        }}>{DateUtil.dateToDayName(dateString)}</div>
                        : <div style={{display: "inline-block", width: "12rem", margin: "0.3rem"}}/>}
                    <DateInput onChange={this.onDateChange} value={dateString}/>
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <span style={{}}>klockan</span>
                    <TimeInput onChange={this.onTimeChange} value={timeString} />
                    <div style={{width: "11.5rem"}}/>
                </div>
                <div style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                    <LinkButton onClick={() => this.setState({edit: false})}/>
                    <button style={{width: "20rem", marginLeft: "2rem", alignSelf: "flex-end"}}
                            onClick={this.saveClicked} disabled={this.saveButtonDisabled()}>Spara
                    </button>
                </div>
            </div>);
        }
        return (
            <div style={{
                margin: "1rem auto 5rem auto",
                display: "flex",
                fontSize: fontSize,
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <div style={{textAlign: "center"}}>Omgångens slut o sista tid att bokföra resultat:</div>
                {admin ?
                    <LinkButton onClick={this.editMode} style={{textAlign: "center"}}
                                text={DateUtil.dateToDayName(date) + " " + date + "kl " + timeString}/>
                    :
                    <div style={{textAlign: "center"}}>{DateUtil.dateToDayName(date)} {date} kl {timeString}</div>}
            </div>
        );
    }

    editMode() {
        this.setState({edit: true});
    }

    onDateChange(currentValue: string, valid: boolean) {
        this.setState({
            dateString: currentValue,
            dateValid: valid
        })
    }

    onTimeChange(currentValue: string, valid: boolean) {
        this.setState({
            timeString: currentValue,
            timeValid: valid
        })
    }

    private saveButtonDisabled = (): boolean => {
        const {dateValid, timeValid, dateString, timeString, originalTimeString} = this.state;
        const {date} = this.props;
        return !dateValid || !timeValid || (dateString === date && timeString === originalTimeString);
    }

    async saveClicked() {
        this.setState({loading: true});
        const {session, groupplayId} = this.props;
        const {dateString, timeString} = this.state;
        const hour : number = +timeString.slice(0,2);
        const minute : number = +timeString.slice(2,4);
        if(session) {
            const request: UpdateRoundOpenUntilDto = {
                date: dateString,
                hour: hour,
                minute: minute
            };

            await updateRoundOpenUntil(groupplayId, this.props.roundNr, request, session.sessionId);
            this.props.onChange();
        }

    }
};