import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import {newAccount} from "../../api/api"
import FullPageMessage from "../../components/FullPageMessage"
import BasePage, {BasePageProps} from "../BasePage"
import EmailInput from "../../components/EmailInput"
import TextInput from "../../components/TextInput"
import PhoneInput from "../../components/PhoneInput"
import {ErrorMessage} from "../../components/ErrorMessage"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"
import {ErrorType} from "../../api/dtos"

interface NewAccountPageProps {
    redirectTo: string
}

interface State {
    errorMessage?: string
    redirectTo?: string
    showItsDone: boolean
    email: string
    firstName: string
    lastName: string
    phone: string
    password1: string
    password2: string
    fatalError: boolean
    emailValid: boolean
    phoneValid: boolean
    passwordsInvalid: boolean
}

type Props = BasePageProps & NewAccountPageProps & RouteComponentProps<{ groupplayId: string }>

class NewAccountPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showItsDone: false,
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            password1: '',
            password2: '',
            fatalError: false,
            emailValid: true,
            phoneValid: true,
            passwordsInvalid: false
        };
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
        this.handleLastnameChange = this.handleLastnameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handlePassword1Change = this.handlePassword1Change.bind(this);
        this.handlePassword2Change = this.handlePassword2Change.bind(this);
        this.goBack = this.goBack.bind(this);
        this.saveDisabled = this.saveDisabled.bind(this);
    }

    handleEmailChange(currentValue: string, valid: boolean) {
        this.setState({email: currentValue, emailValid: valid});
    }

    handleFirstnameChange(currentValue: string) {
        this.setState({firstName: currentValue});
    }

    handleLastnameChange(currentValue: string) {
        this.setState({lastName: currentValue});
    }

    handlePhoneChange(currentValue: string, valid: boolean) {
        this.setState({phone: currentValue, phoneValid: valid});
    }

    handlePassword1Change(currentValue: string) {
        this.setState({
            password1: currentValue,
            passwordsInvalid: this.state.password2 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }

    handlePassword2Change(currentValue: string) {
        this.setState({
            password2: currentValue,
            passwordsInvalid: this.state.password1 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        return (
            <BasePage {...this.props} fatalError={this.state.fatalError}>
                {this.state.showItsDone ? this.renderItsDone() : this.renderForm()}
            </BasePage>
        );
    }

    renderForm() {
        const fontSize = mobile ? "3rem" : "1rem";
        const textMargin = mobile ? "1.5rem" : "0.5rem";
        return (
            <>
                <div style={{margin: "2rem auto 2rem auto", fontSize: fontSize}}>Nytt konto hos gruppspelet.se</div>
                <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                    <EmailInput onChange={this.handleEmailChange} autoFocus={true}/>
                    <TextInput onChange={this.handleFirstnameChange} placeHolder={"Förnamn"}/>
                    <TextInput onChange={this.handleLastnameChange} placeHolder={"Efternamn"}/>
                    <PhoneInput onChange={this.handlePhoneChange} allowEmpty={true}
                                placeHolder={"Telefon"}/>
                    <TextInput onChange={this.handlePassword1Change} placeHolder={"Lösenord"}
                               invalid={this.state.passwordsInvalid} password={true}/>
                    <TextInput onChange={this.handlePassword2Change}
                               placeHolder={"Lösenord igen"}
                               invalid={this.state.passwordsInvalid} password={true}/>
                    <ErrorMessage text={this.state.errorMessage}/>
                    <button onClick={this.handleSaveClick} disabled={this.saveDisabled()}>Skapa konto</button>

                    <LinkButton style={{fontSize: fontSize, margin: textMargin, textAlign: "right"}} onClick={this.goBack}/>
                </div>
            </>
        );
    }

    saveDisabled(): boolean {
        return !this.state.emailValid ||
            !this.state.phoneValid ||
            this.state.firstName.length === 0 ||
            this.state.lastName.length === 0 ||
            this.state.phone.length === 0 ||
            this.state.password1.length < 4 ||
            this.state.passwordsInvalid;
    }

    renderItsDone() {
        return (
            <FullPageMessage text="Du kommer nu få ett mail med en länk för att verifiera din E-mail adress."/>
        );
    }

    async handleSaveClick() {
        this.setState({errorMessage: ''});
        try {
            await newAccount({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                password: this.state.password1
            });
            this.setState({showItsDone: true});
        } catch (error:any) {
            console.log('error type:' + error.type);
            switch (error.type) {
                case ErrorType.INVALID_EMAIL:
                    this.setState({errorMessage: "Felaktig E-mail address"});
                    break;
                case ErrorType.EMAIL_ALREADY_REGISTERED:
                    this.setState({errorMessage: "Det finns redan ett konto med samma E-mail"});
                    break;
                case ErrorType.SEND_MAIL_FAILURE:
                    this.setState({errorMessage: "Misslyckades att maila: " + error.message});
                    break;
                default:
                    this.setState({errorMessage: "", fatalError: true});
                    break;
            }
        }
    }

    goBack() {
        this.setState({redirectTo: this.props.redirectTo});
    }

}

export default NewAccountPage;