import * as React from "react"
import Checkbox from "../../../components/Checkbox"
import {BookingSettingsDto} from "../../../api/dtos"
import BasePage, {BasePageProps} from "../../BasePage"
import {getBookingSettings, saveBookingSettings} from "../../../api/api"
import {RouteComponentProps} from "react-router"
import {mobile} from "../../../App"
import Panel from "../../../components/Panel"
import NumberInputWithLabel from "../../../components/NumberInputWithLabel"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    bookingSettings?: BookingSettingsDto
    unbookUnusedTimesEnabled: boolean
    unbookBeforeHours: number
    unbookBeforeDays: number
    matchMoveAllowedHours: number
    matchMoveAllowedMinutes: number
    loading: boolean
    fatalError: boolean
}

export default class BookingSettingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
            unbookBeforeHours: 0,
            unbookBeforeDays: 0,
            unbookUnusedTimesEnabled: false,
            matchMoveAllowedHours: 0,
            matchMoveAllowedMinutes: 0
        };
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params;
        if (this.props.session) {
            try {
                const dto: BookingSettingsDto = await getBookingSettings(groupplayId, this.props.session.sessionId);
                const dtoUnbookBeforeDays = Math.trunc(dto.unbookUnusedTimesHoursBefore / 24);
                const dtoUnbookBeforeHours = dto.unbookUnusedTimesHoursBefore - 24 * dtoUnbookBeforeDays;
                const dtoMoveAllowedHours = Math.trunc(dto.matchMoveAllowedMinutesBefore / 60);
                const dtoMoveAllowedMinutes = dto.matchMoveAllowedMinutesBefore - 60 * dtoMoveAllowedHours;
                this.setState({
                    bookingSettings: dto,
                    unbookBeforeHours: dtoUnbookBeforeHours,
                    unbookBeforeDays: dtoUnbookBeforeDays,
                    unbookUnusedTimesEnabled: dto.unbookUnusedTimesEnabled,
                    matchMoveAllowedHours: dtoMoveAllowedHours,
                    matchMoveAllowedMinutes: dtoMoveAllowedMinutes,
                    loading: false
                })
            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    render() {
        const {
            loading, fatalError, unbookUnusedTimesEnabled, unbookBeforeHours, unbookBeforeDays,
            matchMoveAllowedHours, matchMoveAllowedMinutes
        } = this.state;
        const fontSize = mobile ? "3rem" : "1rem";
        return (
            <BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Panel center={true}>
                        <span style={{marginTop: "2rem", fontSize: fontSize, textAlign: "center"}}>
                        Senast hur lång tid innan matchstart ska man kunna boka av/flytta match-tider?
                    </span>
                        <NumberInputWithLabel label={"Timmar:"} onChange={this.matchMoveHoursChanged}
                                              value={matchMoveAllowedHours} min={0} max={96} />
                        <NumberInputWithLabel label={"Minuter:"} onChange={this.matchMoveMinutesChanged}
                                              value={matchMoveAllowedMinutes} min={0} max={59} />
                    </Panel>
                    <Panel center={true}>
                            <span style={{
                                width: mobile ? "34rem" : "24rem",
                                textAlign: "end",
                                marginRight: "1rem",
                                fontSize: fontSize
                            }}>Boka av oanvända tider i bokningssystemet:</span>
                        <div style={{margin:"1rem"}}>
                            <Checkbox checked={unbookUnusedTimesEnabled} onChange={this.unbookingEnabledCheckboxClicked}
                                      id={"unbookingEnabled"}/>
                        </div>
                        <span style={{
                            fontSize: fontSize,
                            textAlign: "center",
                            color: unbookUnusedTimesEnabled ? "inherit" : "var(--buttonDisabled)"
                        }}>
                            Hur lång tid innan skall oanvända bokningar bokas av (0-14 dygn)?
                        </span>
                        <NumberInputWithLabel label={"Dygn:"} onChange={this.unbookDaysBeforeChanged} disabled={!unbookUnusedTimesEnabled}
                                              value={unbookBeforeDays} min={0} max={14} />
                        <NumberInputWithLabel label={"Timmar:"} onChange={this.unbookHoursBeforeChanged} disabled={!unbookUnusedTimesEnabled}
                                              value={unbookBeforeHours} min={0} max={23} />
                    </Panel>
                    <button style={{padding: "0 1rem"}} onClick={this.handleSaveClick}
                            disabled={this.saveDisabled()}>Spara
                    </button>
                </div>
            </BasePage>
        );
    }

    private handleSaveClick = async () => {
        if (this.props.session) {
            this.setState({loading: true});
            const {groupplayId} = this.props.match.params;
            const {unbookBeforeHours, unbookBeforeDays, unbookUnusedTimesEnabled, matchMoveAllowedMinutes, matchMoveAllowedHours} = this.state;
            const unbookOnlyHours = unbookBeforeHours + 24 * unbookBeforeDays;
            const moveAllowedOnlyMinutes = matchMoveAllowedHours * 60 + matchMoveAllowedMinutes;
            const request: BookingSettingsDto = {
                unbookUnusedTimesEnabled: unbookUnusedTimesEnabled,
                unbookUnusedTimesHoursBefore: unbookOnlyHours,
                matchMoveAllowedMinutesBefore: moveAllowedOnlyMinutes
            };
            try {
                await saveBookingSettings(request, groupplayId, this.props.session.sessionId);
                this.setState({
                    loading: false,
                    bookingSettings: request
                });
            } catch (error:any) {
                console.log('error type:' + error.type);
                switch (error.type) {
                    default:
                        console.log("FATAL ERROR");
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    };

    private saveDisabled = (): boolean => {
        const {unbookBeforeHours, unbookBeforeDays, bookingSettings, unbookUnusedTimesEnabled, matchMoveAllowedHours, matchMoveAllowedMinutes} = this.state;
        const unbookOnlyHours = unbookBeforeHours + 24 * unbookBeforeDays;
        const matchMoveOnlyMinutes = matchMoveAllowedMinutes + 60 * matchMoveAllowedHours;
        const validRange: boolean = unbookOnlyHours > 0 && unbookOnlyHours < 14 * 24 && matchMoveOnlyMinutes > 0;
        return !validRange ||
            (bookingSettings !== undefined &&
                bookingSettings.unbookUnusedTimesHoursBefore === unbookOnlyHours &&
                unbookUnusedTimesEnabled === bookingSettings.unbookUnusedTimesEnabled &&
                matchMoveOnlyMinutes === bookingSettings.matchMoveAllowedMinutesBefore);
    };

    private matchMoveHoursChanged = (value: number) => {
        this.setState({matchMoveAllowedHours: value});
    };

    private matchMoveMinutesChanged = (value: number) => {
        this.setState({matchMoveAllowedMinutes: value});
    };

    private unbookDaysBeforeChanged = (value: number) => {
        this.setState({unbookBeforeDays: value});
    };

    private unbookHoursBeforeChanged = (value: number) => {
        this.setState({unbookBeforeHours: value});
    };

    private unbookingEnabledCheckboxClicked = (checked: boolean) => {
        this.setState({unbookUnusedTimesEnabled: checked});
    };
}
