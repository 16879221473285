import {SessionDto} from "../api/dtos";
import {Redirect, RouteComponentProps} from "react-router";
import * as React from "react";
import PageHeader from "./PageHeader";
import FullPageMessage from "../components/FullPageMessage";
import FullPageSpinner from "../components/FullPageSpinner";
import {mobile} from "../App";

export interface BasePageProps {
    disableHeader?: boolean
    session?: SessionDto
    onSessionChange: (rememberMe: boolean, session?: SessionDto) => void
    pagePath: (pagePath: string) => void
    loading?: boolean
    adminPage?: boolean
    noLoginButton?: boolean
    hideScheduleButton?: boolean
    hideStandingsButton?: boolean
    sessionRequired?: boolean
    center?: boolean
}

interface BasePagePropsInternal {
    fatalError: boolean
}

interface State {
    fatalError: boolean
}

type Props = BasePageProps & BasePagePropsInternal & RouteComponentProps<{ groupplayId: string }>

export default class BasePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false
        };
        this.fatalError = this.fatalError.bind(this);
    }

    componentDidMount() {
        this.props.pagePath(this.props.location.pathname);
    }


    render() {
        const {groupplayId} = this.props.match.params;
        if (this.props.adminPage && (!this.props.session || !this.props.session.adminGroupplayIds.includes(groupplayId))) {
            return (<Redirect to={'/gruppspel/' + groupplayId}/>);
        }
        if (this.props.sessionRequired && !this.props.session) {
            if (groupplayId) {
                return (<Redirect to={'/gruppspel/' + groupplayId}/>);
            } else {
                return (<Redirect to={'/'}/>);
            }
        }
        if (this.props.fatalError || this.state.fatalError) {
            return (<FullPageMessage text='Något gick fel... Ladda om sidan och försök igen om ett litet tag!'/>);
        }
        if (this.props.loading) {
            return (<FullPageSpinner/>);
        }
        const displayHeader = !this.props.disableHeader;

        const marginTop = displayHeader ? (mobile ? "11rem" : "2.5rem") : 0;
        return (
            <div style={{height: "100%", display: "flex", flexFlow: "column"}}>
                {displayHeader &&
                    <PageHeader noLoginButton={this.props.noLoginButton} groupplayId={groupplayId}
                                session={this.props.session} fatalError={this.fatalError}
                                onSessionChange={this.props.onSessionChange}
                                hideStandingsButton={this.props.hideStandingsButton}
                                hideScheduleButton={this.props.hideScheduleButton}/>}
                <div style={{marginTop: marginTop, flexGrow: 1, display: "flex", flexDirection: "column"}}>
                    {this.children()}
                </div>
            </div>
        );
    }

    children = () => {
        const marginTop = mobile ? "0" : "3rem";
        if (this.props.center) {
            return <div style={{
                margin: marginTop + " auto 0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                {this.props.children}
            </div>
        }
        return this.props.children
    }

    fatalError() {
        this.setState({fatalError: true});
    }

}
